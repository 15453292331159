<template>
  <v-btn
    color="white"
    elevation="2"
    class="text--primary"
    icon
    large
    @click="centeringCurrentPosition"
  >
    <v-icon>mdi-crosshairs-gps</v-icon>
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
import { EventBus } from "@/event.js";

export default {
  data() {
    return {
      watcher: null,
    };
  },
  computed: mapState(["currentPosition"]),
  methods: {
    centeringCurrentPosition() {
      if (!this.$store.state.geoPermission) {
        EventBus.$emit("geoPermission", this.positionInit);
      } else {
        EventBus.$emit("centering", this.currentPosition);
      }
    },
    positionInit() {
      const that = this;
      this.watcher = navigator.geolocation.watchPosition(
        (position) => {
          that.$store.commit("currentPosition", {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          that.$store.commit(
            "currentPositionAccuracy",
            position.coords.accuracy > 1000 ? 1000 : position.coords.accuracy
          );
        },
        () => {
          that.$store.commit("geoPermission", false);
        }
      );
      navigator.geolocation.getCurrentPosition(
        (position) => {
          that.$store.commit(
            "currentPositionAccuracy",
            position.coords.accuracy > 1000 ? 1000 : position.coords.accuracy
          );
          that.$store.commit("currentPosition", {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          EventBus.$emit("centering", this.currentPosition);
        },
        () => {
          EventBus.$emit("error", "現在地を取得することができませんでした。");
          that.$store.commit("geoPermission", false);
        }
      );
    },
  },
  mounted() {
    if (this.$store.state.geoPermission) {
      this.positionInit();
    } else {
      EventBus.$emit("geoPermission", this.positionInit);
    }
  },
};
</script>