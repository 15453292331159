<template>
  <div class="settingPanel">
    <svg
      width="150"
      height="150"
      viewBox="-50 -100 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="button"
        v-bind:class="{
          activeBase: !(hasRapid || hasNormal || allDay || dealer),
          inactiveBase: hasRapid || hasNormal || allDay || dealer,
        }"
        cx="0"
        cy="0"
        r="45"
        fill-opacity="0.75"
        stroke="gray"
        @click="clicked0"
      />
      <text
        class="button"
        v-bind:class="{
          activeIcon: !(hasRapid || hasNormal || allDay || dealer),
          inactiveIcon: hasRapid || hasNormal || allDay || dealer,
        }"
        x="-24.5"
        y="13.5"
        font-size="40"
        @click="clicked0"
      >
        All
      </text>
      <path
        class="button"
        v-bind:class="{ activeBase: hasRapid, inactiveBase: !hasRapid }"
        v-bind:d="path1"
        fill-opacity="0.75"
        stroke="gray"
        @click="clicked1"
      />
      <path
        class="button"
        v-bind:class="{ activeIcon: hasRapid, inactiveIcon: !hasRapid }"
        v-bind:d="tripleLightningPath"
        @click="clicked1"
        transform="translate(-5, -95)"
      />
      <path
        class="button"
        v-bind:class="{ activeBase: hasNormal, inactiveBase: !hasNormal }"
        v-bind:d="path2"
        fill-opacity="0.75"
        stroke="gray"
        @click="clicked2"
      />
      <path
        class="button"
        v-bind:class="{ activeIcon: hasNormal, inactiveIcon: !hasNormal }"
        v-bind:d="lightningPath"
        @click="clicked2"
        transform="translate(32, -58)"
      />
      <path
        class="button"
        v-bind:class="{ activeBase: allDay, inactiveBase: !allDay }"
        v-bind:d="path3"
        fill-opacity="0.75"
        stroke="gray"
        @click="clicked3"
      />
      <path
        class="button"
        v-bind:class="{ activeIcon: allDay, inactiveIcon: !allDay }"
        v-bind:d="alldayPath"
        @click="clicked3"
        transform="scale(0.45) translate(95, 35)"
      />
      <path
        class="button"
        v-bind:class="{ activeBase: dealer, inactiveBase: !dealer }"
        v-bind:d="path4"
        fill-opacity="0.75"
        stroke="gray"
        @click="clicked4"
      />
      <path
        class="button"
        v-bind:class="{ activeIcon: dealer, inactiveIcon: !dealer }"
        v-bind:d="dealerPath"
        @click="clicked4"
        transform="scale(0.6), translate(25, 84)"
      />
    </svg>
  </div>
</template>

<script>
import { EventBus } from "@/event.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      path1: annularSector(0, 0, 90, 135, 45, 95),
      path1Selected: annularSector(0, 0, 90, 135, 45, 100),
      path2: annularSector(0, 0, 135, 180, 45, 95),
      path2Selected: annularSector(0, 0, 135, 180, 45, 100),
      path3: annularSector(0, 0, 180, 225, 45, 95),
      path3Selected: annularSector(0, 0, 180, 225, 45, 100),
      path4: annularSector(0, 0, 225, 270, 45, 95),
      path4Selected: annularSector(0, 0, 225, 270, 45, 100),
      selectedFill: "#FFFF00",
      selectedBaseFill: "#66ccff",
      lightningPath: "M40 30 L34 30 L36 16 L24 34 L30 34 L28 48 Z",
      tripleLightningPath:
        "M24 30 L18 30 L20 16 L8 34 L14 34 L12 48 Z M40 30 L34 30 L36 16 L24 34 L30 34 L28 48 Z M56 30 L50 30 L52 16 L40 34 L46 34 L44 48 Z",
      alldayPath:
        "M2.59,3.57A23.62,23.62,0,0,1,8.35,1a24,24,0,0,1,7-1Q22.47,0,26.6,3.82t4.14,10.65a19.58,19.58,0,0,1-.72,5.4,21.69,21.69,0,0,1-2,4.79A31.52,31.52,0,0,1,25,29.05a51.19,51.19,0,0,1-3.85,4.14c-1.49,1.44-2.91,2.79-4.25,4s-2.67,2.45-4,3.6H31.18v8H1.37L0,43.05c1.3-1.35,2.48-2.58,3.56-3.68l3.06-3.14c1-1,1.89-2,2.78-2.92l2.77-3,3.17-3.61A30.46,30.46,0,0,0,18.11,23a21.89,21.89,0,0,0,1.94-3.83,10.77,10.77,0,0,0,.76-4,7.1,7.1,0,0,0-1.95-5.3A7.37,7.37,0,0,0,13.46,8a15.12,15.12,0,0,0-4.75.69A17,17,0,0,0,4.9,10.44Z M62.18,36.58V48.82H52.46V36.58H33.09L31.65,29.3,50.15.86h12V28.94h6.48v7.64ZM52.46,9.86,40.86,28.94h11.6Z M71.72,48.82V14.93H78.3V28.27a7.14,7.14,0,0,1,2.85-2.49A8.44,8.44,0,0,1,84.87,25a7.43,7.43,0,0,1,3.87,1,7.73,7.73,0,0,1,2.61,2.57,12,12,0,0,1,1.49,3.74,20.27,20.27,0,0,1,.48,4.44V48.82H86.74V36.62a9.4,9.4,0,0,0-1-4.8,3.51,3.51,0,0,0-3.24-1.63,4.65,4.65,0,0,0-2.5.67,6.27,6.27,0,0,0-1.72,1.59V48.82Z",
      dealerPath:
        "M0,0H14.47A34.67,34.67,0,0,1,26,1.8a26.24,26.24,0,0,1,8.78,5,22.25,22.25,0,0,1,5.62,7.6,23,23,0,0,1,2,9.58,23,23,0,0,1-2,9.57,22.25,22.25,0,0,1-5.62,7.6,26.24,26.24,0,0,1-8.78,5A34.67,34.67,0,0,1,14.47,48H0ZM14.69,40a22.17,22.17,0,0,0,7-1.08,16.21,16.21,0,0,0,5.58-3.15,14.07,14.07,0,0,0,3.64-5A16.68,16.68,0,0,0,32.26,24,16.73,16.73,0,0,0,31,17.25a14.16,14.16,0,0,0-3.64-5,16,16,0,0,0-5.58-3.14,21.89,21.89,0,0,0-7-1.09H10.08V40Z",
    };
  },
  methods: {
    clicked0() {
      if (this.hasRapid || this.hasNormal || this.allDay || this.dealer) {
        this.$store.commit("hasRapid", false);
        this.$store.commit("hasNormal", false);
        this.$store.commit("allDay", false);
        this.$store.commit("dealer", false);
        EventBus.$emit("refine");
      }
    },
    clicked1() {
      this.$store.commit("hasRapid", !this.hasRapid);
      EventBus.$emit("refine");
    },
    clicked2() {
      this.$store.commit("hasNormal", !this.hasNormal);
      EventBus.$emit("refine");
    },
    clicked3() {
      this.$store.commit("allDay", !this.allDay);
      EventBus.$emit("refine");
    },
    clicked4() {
      this.$store.commit("dealer", !this.dealer);
      EventBus.$emit("refine");
    },
  },
  computed: {
    ...mapState(["hasRapid", "hasNormal", "allDay", "dealer"]),
  },
};

const deg2rad = function (deg) {
  return (deg * Math.PI) / 180;
};

const annularSector = function (
  centerX,
  centerY,
  startAngle,
  endAngle,
  innerRadius,
  outerRadius
) {
  startAngle = deg2rad(startAngle + 180);
  endAngle = deg2rad(endAngle + 180);
  let p = [
    [
      centerX + innerRadius * Math.cos(startAngle),
      centerY + innerRadius * Math.sin(startAngle),
    ],
    [
      centerX + outerRadius * Math.cos(startAngle),
      centerY + outerRadius * Math.sin(startAngle),
    ],
    [
      centerX + outerRadius * Math.cos(endAngle),
      centerY + outerRadius * Math.sin(endAngle),
    ],
    [
      centerX + innerRadius * Math.cos(endAngle),
      centerY + innerRadius * Math.sin(endAngle),
    ],
  ];
  let angleDiff = endAngle - startAngle,
    largeArc = angleDiff % (Math.PI * 2) > Math.PI ? 1 : 0;
  let commands = [];
  commands.push("M" + p[0].join());
  commands.push("L" + p[1].join());
  commands.push(
    "A" +
      [outerRadius, outerRadius].join() +
      " 0 " +
      largeArc +
      " 1 " +
      p[2].join()
  );
  commands.push("L" + p[3].join());
  commands.push(
    "A" +
      [innerRadius, innerRadius].join() +
      " 0 " +
      largeArc +
      " 0 " +
      p[0].join()
  );
  commands.push("z");
  return commands.join(" ");
};
</script>

<style>
.button {
  cursor: pointer;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition-property: fill;
  transition-duration: 0.2s;
}
.activeBase {
  fill: #191919;
}
.inactiveBase {
  fill: white;
}
.activeIcon {
  fill: #f0f0f0;
}
.inactiveIcon {
  fill: #c0c0c0;
}
</style>
