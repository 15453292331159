<template>
  <div>
    <LegendButton class="legend-btn" />
    <CurrentPositionButton class="current-position-btn" />
    <RefineSettingPanel class="refine-setting-panel" />
  </div>
</template>

<script>
import LegendButton from "@/components/LegendButton.vue";
import CurrentPositionButton from "@/components/CurrentPositionButton.vue";
import RefineSettingPanel from "@/components/RefineSettingPanel.vue";

export default {
  name: "Around",
  components: { LegendButton, CurrentPositionButton, RefineSettingPanel },
  data() {
    return {};
  },
  mounted: function () {},
  beforeDestroy: function () {
    this.$store.commit("start", null);
    this.$store.commit("destination", null);
    this.$store.commit("routeLegs", []);
    this.$store.commit("chargers", []);
    this.$store.commit("polylines", []);
  },
  metaInfo: {
    title: "アプリ",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "お近くの電気自動車用充電器を検索！ルートを設定した充電計画作成にご活用ください。電気自動車を便利に安心して利用する手助けに！",
      },
    ],
  },
};
</script>

<style scoped>
.legend-btn {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  right: 15px;
}
.current-position-btn {
  position: absolute;
  z-index: 1;
  right: 15px;
  bottom: 60px;
}

.refine-setting-panel {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 3px;
}
</style>