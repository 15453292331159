<template>
  <v-btn color="secondary" small @click="openLegendSheet">画面表示の説明</v-btn>
</template>

<script>
import { EventBus } from "@/event.js";

export default {
  methods: {
    openLegendSheet() {
      this.$gtag.event("click", {
        event_category: "showLegend",
      });
      EventBus.$emit("legend", true);
    },
  },
};
</script>